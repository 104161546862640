import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import XhtmlBlock from '../../components/xhtmlBlock/XhtmlBlock';
import Shortcut from '../../components/links/shortcut/Shortcut';

import { getIcon } from '../../sharedJs/icons';

import '../../styles/_grid.scss';
import '../../styles/_utilities.scss';
import './_startPage.scss';


class StartPage extends Component {
    render() {
        return (
            <div className="t_start-page wrapper container-fluid">
                {this.props.model && this.props.model.mainBody &&
                    <XhtmlBlock model={this.props.model.mainBody} id="body" />
                }
                {this.props.model.shortcuts[0] && this.props.model.shortcuts[0].length > 0 &&
                <Fragment>
                    <ul className="u_list-style-none row">
                        {this.props.model.shortcuts[0].map(item => (
                            <li className="col-12 col-lg-6" key={`tileKey_${item.title}-${item.url}`}>
                                <Shortcut
                                    description={item.description}
                                    icon={getIcon(item.icon)}
                                    isBlockShortcut
                                    title={item.heading != null ? item.heading : item.name}
                                    language={this.props.language}
                                    url={item.url && item.url.uri}
                                />
                            </li>
                        ))}
                    </ul>
                </Fragment>
                }
                {this.props.model.shortcuts[1] && this.props.model.shortcuts[1].length > 0 &&
                <Fragment>
                    <ul className="u_list-style-none">
                        {this.props.model.shortcuts[1].map(item => (
                            <li key={`tileKey_${item.title}-${item.url}`}>
                                <Shortcut
                                    description={item.description}
                                    icon={getIcon(item.icon)}
                                    isAudienceShortcut
                                    title={item.heading}
                                    language={this.props.language}
                                    url={item.url && item.url.uri}
                                />
                            </li>
                        ))}
                    </ul>
                </Fragment>
                }
                {this.props.model.shortcuts[2] && this.props.model.shortcuts[2].length > 0 &&
                <Fragment>
                    <ul className="u_list-style-none row">
                        {this.props.model.shortcuts[2].map(item => (
                            <li className="col-12 col-lg-6" key={`tileKey_${item.title}-${item.url}`}>
                                <Shortcut
                                    description={item.description}
                                    isBlockShortcut
                                    title={item.heading}
                                    language={this.props.language}
                                    url={item.url && item.url.uri}
                                />
                            </li>
                        ))}
                    </ul>
                </Fragment>
                }
            </div>
        );
    }
}

StartPage.propTypes = {
    model: PropTypes.any,
    language: PropTypes.object
};
export default StartPage;
