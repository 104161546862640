import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import axios from 'axios';
import SvgElement from '../../uiElements/svgElement/SvgElement';


import downloadIcon from '../../../assets/icons/icon-download.svg';
import backArrow from '../../../assets/icons/icon-arrow-right-darkblue.svg';
import folderOpenIcon from '../../../assets/icons/icon_folder_opened.svg';
import folderClosedIcon from '../../../assets/icons/icon_folder_closed.svg';

import './_fileExplorerBlock.scss';

class FileExplorerBlock extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            orgData: [],
            currentFolder: null,
            currentFolderId: null
        };
        this.getFileExplorerItems();
    }

    getFileExplorerItems() {
        axios.get(`/FileExplorer/GetFileExplorerItems?blockid=${this.props.model.contentLink.id}`)
            .then((response) => {
                this.setState({
                    orgData: response.data
                });
            });
    }

    openFolder(folder) {
        this.setState({
            currentFolder: folder,
            currentFolderId: folder.id
        });
    }

    handleBackButton() {
        const { currentFolderId, orgData } = this.state;

        // is this level 1?
        const found = orgData.find(({ id }) => id === currentFolderId);
        if (found) {
            this.setState({
                currentFolder: null,
                currentFolderId: null
            });
        } else {
            // Need to delve deeper into json data and find parent
            this.findParentElement(currentFolderId, orgData);
        }
    }

    findParentElement(findId, items) {
        items.some((item) => {
            if (item.children) {
                const foundChild = item.children.find(({ id }) => id === findId);
                if (foundChild) {
                    this.setState({
                        currentFolder: item,
                        currentFolderId: item.id
                    });
                    return true;
                }
                this.findParentElement(findId, item.children);
            }
            return false;
        });
    }

    renderItems(elem, isRoot) {
        const items = (isRoot ? elem : elem.children);
        return (
            <div className="c_sharepoint_explorer">
                {!isRoot &&
                    <Fragment>
                        <button
                            onClick={e => this.handleBackButton(e)}
                            className="c_sharepoint_explorer_button__link c_sharepoint_explorer_button__link--back"
                        >
                            <SvgElement
                                src={backArrow}
                                title="Se filer i mappen"
                                desc="Se filer i mappen"
                                className="c_sharepoint_explorer_button__link--back-icon"
                            />
                            Tilbake
                        </button>

                        <div className="c_sharepoint_explorer_sub-heading">
                            <SvgElement
                                src={folderOpenIcon}
                                title="Se filer i mappen"
                                desc="Se filer i mappen"
                                className="c_sharepoint_explorer_folder_icon--open"
                            />
                            {elem.name}
                        </div>
                    </Fragment>
                }
                {items.map((item) => {
                    const type = item.children ? 'folder' : 'file';
                    if (type === 'folder') {
                        return (
                            <button
                                key={item.id}
                                onClick={e => this.openFolder(item, e)}
                                className="c_sharepoint_explorer_item c_sharepoint_explorer_button__link"
                            >
                                <SvgElement
                                    src={folderClosedIcon}
                                    title="Se filer i mappen"
                                    desc="Se filer i mappen"
                                    className="c_sharepoint_explorer_folder_icon--open"
                                />
                                {item.name}
                            </button>
                        );
                    }
                    return (
                        <a
                            key={item.id}
                            href={item.url}
                            className="c_sharepoint_explorer_item c_sharepoint_explorer_button-link--download"
                        >
                            <SvgElement
                                src={downloadIcon}
                                title="Last ned fil"
                                desc="Last ned fil"
                                className="c_sharepoint_explorer_download_icon"
                            />
                            {item.name}
                        </a>
                    );
                })}
            </div>
        );
    }

    render() {
        const data = this.state.currentFolder ? this.state.currentFolder : this.state.orgData;
        const isRoot = !this.state.currentFolder;
        return (
            <div className="c_sharepoint_explorer_container">
                {this.props.model.heading && this.props.model.heading !== '' &&
                    <h2>{this.props.model.heading}</h2>
                }
                {this.renderItems(data, isRoot)}
            </div>
        );
    }
}

FileExplorerBlock.propTypes = {
    model: PropTypes.object
};

export default FileExplorerBlock;