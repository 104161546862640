import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { translations } from '../../../sharedJs/translations';
import EpiLink from '../../links/epiLink/EpiLink';
import NavMenu from '../../navMenu/NavMenu';
import menuIcon from '../../../assets/icons/icon-menu-white.svg';
import crossIcon from '../../../assets/icons/cross.svg';

// import HeaderSearchField from '../../headerSearchField/HeaderSearchField';

// import NavMenu from '../../navMenu/NavMenu';// import BreadCrumbs from '../../breadCrumbs/BreadCrumbs';
import SvgElement from '../../uiElements/svgElement/SvgElement';
// import HeaderSearchField from '../../headerSearchField/HeaderSearchField';
// import { bulkSetAttribute } from '../../../sharedJs/utilityFunctions';

import '../../../styles/_utilities.scss';
import '../../../styles/_grid.scss';
import './_header.scss';

import logo from '../../../assets/logo/logo.svg';

class Header extends Component {
    constructor() {
        super();
        this.state = {
            navMenuIsOpen: false
        };
        autoBind(this);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.location && prevProps.location) && (this.props.location.pathname !== prevProps.location.pathname)) {
            this.setState({ navMenuIsOpen: false, mobileSearchIsOpen: false, closeMobileSearch: false }); // eslint-disable-line
        }
    }

    getHeaderClassName() {
        let cN = '';
        if (this.props.location && this.props.location.pathname === '/') {
            cN = `${this.props.className}--miljostatus-frontpage`;
        }
        return `${this.props.className} ${this.props.className}--miljostatus ${cN}`;
    }

    getLogo(isDesktop) {
        return isDesktop;
        /* if (isDesktop) {
            return this.props.language.name === 'en' ? desktopMiljostatusLogoEnglish : desktopMiljostatusLogo;
        }
        return this.props.language.name === 'en' ? mobileMiljostatusLogoEnglish : mobileMiljostatusLogo; */
    }

    getIcon(icon) {
        switch (icon) {
        case 'logo':
            return logo;
        case 'close':
            return crossIcon;
        case 'hamburger':
            return menuIcon;
        default:
            return null;
        }
    }
    focusHandler() {
        if (!this.state.navMenuIsOpen) {
            this.setState({ navMenuIsOpen: true });
        }
    }
    toggleNavMenu() {
        window.scrollTo(0, 0);
        this.setState({
            navMenuIsOpen: !this.state.navMenuIsOpen
        });
    }

    // renderSuggestions(showOnMobile) {
    //     return (
    //         <div className={`c_search-field-suggestions${showOnMobile ? '' : ' u_hidden--mobile'}`}>
    //             <ul className="c_search-field-suggestions__list">
    //                 {this.props.searchSuggestions.map(item => (
    //                     <li
    //                         className="c_search-field-suggestions__list-item"
    //                         key={`suggestionKey__${item.title}_${item.url}`}
    //                     >
    //                         <EpiLink url={item.url}>
    //                             <span className="name">{item.title}</span>
    //                         </EpiLink>
    //                     </li>
    //                 ))}
    //                 <li className="c_search-field-suggestions__list-item" key="suggestionKey__toSearchPage">
    //                     <EpiLink url={`${this.pathToSearchPage}?q=${this.props.searchInputValue}`}>
    //                         <span className="name">{`Vis alle treff på "${this.props.searchInputValue}"`}</span>
    //                     </EpiLink>
    //                 </li>
    //             </ul>
    //         </div>
    //     );
    // }

    render() {
        const { pathname } = this.props.location;
        let currentLevel = null;
        const homePath = '/';

        const translate = translations(this.props.language.name);
        const {
            header
        } = this.props.epiDataModel;

        if (header && header.menu && header.menu.length > 0) {
            if (pathname.toString() === '/') {
                currentLevel = header.menu.find(item => item.pageName === '');
            } else {
                currentLevel = header.menu.find(item => (item.url.toLowerCase() === pathname.toString().toLowerCase()));
            }
        }

        return (
            <header className="c_main-header">
                <div className="c_main-header__content-wrapper wrapper">
                    <EpiLink
                        url={homePath}
                        className="c_main-header__go-home"
                        aria-label={translate.App.BackToFrontPage}
                    >
                        <span className="c_main-logo">
                            <SvgElement
                                src={this.getIcon('logo')}
                                title={translate.App.BackToFrontPage}
                                desc={translate.App.GoBackToFrontPage}
                            />
                        </span>
                        <h1>{header && header.startPageName}</h1>
                    </EpiLink>
                    <button
                        text="Meny"
                        onClick={() => this.toggleNavMenu()}
                        className="c_button c_nav-toggle-button"
                        aria-label={translate.App.Menu}
                        aria-expanded={this.state.navMenuIsOpen}
                        aria-controls="masterNavMenu"
                    >
                        <span className="c_nav-toggle-button__text u_hidden--mobile">
                            {translate.App.Menu}
                        </span>
                        {this.state.navMenuIsOpen ?
                            <SvgElement
                                src={this.getIcon('close')}
                                title={translate.App.CloseMenu}
                                desc={translate.App.ButtonToCloseMenu}
                                className="c_nav-toggle-button__icon c_nav-toggle-button__icon--close-menu u_hidden--desktop"
                            />
                            :
                            <SvgElement
                                src={this.getIcon('hamburger')}
                                title={translate.App.OpenMenu}
                                desc={translate.App.ButtonToOpenMenu}
                                className="c_nav-toggle-button__icon u_hidden--desktop"
                            />
                        }
                        <SvgElement
                            src={this.getIcon('hamburger')}
                            title={translate.App.OpenMenu}
                            desc={translate.App.ButtonToOpenMenu}
                            className="c_nav-toggle-button__icon u_hidden--mobile"
                        />
                    </button>
                    <NavMenu
                        isOpen={this.state.navMenuIsOpen}
                        closeMenu={this.toggleNavMenu}
                        location={this.props.location}
                        focusHandler={this.focusHandler}
                        currentLevel={currentLevel}
                    />
                </div>
            </header>
        );
    }
}

Header.propTypes = {
    className: PropTypes.string.isRequired,
    location: PropTypes.object,
    // searchInputValue: PropTypes.string.isRequired,
    // searchSuggestions: PropTypes.array,
    language: PropTypes.object,
    epiDataModel: PropTypes.object
    // epiDataModelLoaded: PropTypes.bool
};

const mapStateToProps = state => ({
    searchInputValue: state.siteSearch.inputValue,
    searchSuggestions: state.siteSearch.searchSuggestions,
    language: state.app.language,
    epiDataModel: state.app.epiDataModel,
    epiDataModelLoaded: state.app.epiDataModelLoaded
});

export default connect(mapStateToProps, {})(Header);