import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getComponentTypeForContent from './api/getComponentTypeForContent';
import { EPI_COMPONENTS } from './epiComponents';
import { updateContext, updateModelByUrl } from './actions/index';
import { showGlossary } from './actions/glossaryActions';
import LoadingSpinner from './components/uiElements/loadingSpinner/LoadingSpinner';

class PageComponentSelector extends Component {
    constructor(props) {
        super(props);
        if (props.epiDataModelLoaded) {
            this.state = {
                model: props.epiDataModel,
                modelLoaded: props.epiDataModelLoaded,
                epiComponent: getComponentTypeForContent(props.epiDataModelLoaded, EPI_COMPONENTS)
            };
        } else {
            this.state = {
                modelLoaded: false
            };
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.epiDataModelLoaded) {
            this.setState({
                model: nextProps.epiDataModel,
                modelLoaded: nextProps.epiDataModelLoaded,
                epiComponent: getComponentTypeForContent(nextProps.epiDataModel, EPI_COMPONENTS)
            });
            document.title = nextProps.epiDataModel.name;
        }
    }

    render() {
        if (this.props.isLoading) {
            return (
                <main id="content" tabIndex="-1" style={{ outline: 'none' }} className="c_loading-main">
                    <LoadingSpinner />
                </main>
            );
        }

        if (this.state.modelLoaded) {
            const PageComponent = this.state.epiComponent.component;
            return (
                <main id="content" tabIndex="-1" style={{ outline: 'none' }} className="c_mstatus-main">
                    <PageComponent
                        model={this.state.model}
                        {...this.props}
                    />
                </main>
            );
        }

        return (
            <main id="content" tabIndex="-1" style={{ outline: 'none' }}>
            </main>
        );
    }
}

PageComponentSelector.propTypes = {
    epiDataModel: PropTypes.any,
    epiDataModelLoaded: PropTypes.any,
    glossaryIsOpen: PropTypes.bool,
    glossaryText: PropTypes.string,
    glossaryOffset: PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.string,
        right: PropTypes.string
    }),
    glossaryArrowLeft: PropTypes.bool,
    showGlossary: PropTypes.func,
    isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
    epiDataModel: state.app.epiDataModel,
    epiDataModelLoaded: state.app.epiDataModelLoaded,
    language: state.app.language,
    isLoading: state.app.isLoading,
    glossaryIsOpen: state.glossary.isOpen,
    glossaryText: state.glossary.text,
    glossaryOffset: state.glossary.offset,
    glossaryArrowLeft: state.glossary.arrowLeft
});

export default connect(mapStateToProps, {
    updateContext,
    updateModelByUrl,
    showGlossary
})(PageComponentSelector);
