import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { translations } from '../../../sharedJs/translations';
import EpiLink from '../../links/epiLink/EpiLink';
import SvgElement from '../../uiElements/svgElement/SvgElement';
import '../../../styles/_grid.scss';
import './_footer.scss';

import logo from '../../../assets/logo/logo.svg';

class Footer extends Component {
    render() {
        if (!this.props.epiDataModel || !this.props.epiDataModelLoaded) {
            return null;
        }

        const {
            email,
            footerLink,
            privacyLink,
            phone,
            shortPhone,
            organisationNumber
        } = this.props.epiDataModel.footer;

        const translate = translations(this.props.language.name);

        return (
            <footer className="c_footer">
                <div className="c_footer__content-wrapper wrapper container-fluid">
                    <div className="row bottom">
                        <div className="inner-wrapper col-4 col-sm-4 col-lg-1">
                            <EpiLink url="/" aria-label="Gå tilbake til forsiden">
                                <SvgElement
                                    src={logo}
                                    title={translate.App.BackToFrontPage}
                                    desc={translate.App.GoBackToFrontPage}
                                    className="c_footer__icon"
                                />
                            </EpiLink>
                        </div>

                        <div className="inner-wrapper inner-wrapper--address col-12 col-sm-12 col-lg-4">
                            <address>
                                {organisationNumber &&
                                <div><span>{translate.App.OrganizationNumber}:</span> {organisationNumber}</div>
                                }

                                {(phone || shortPhone) &&
                                <div><span>{translate.App.Phone}: </span>
                                    {shortPhone &&
                                    <a href={`tel:${shortPhone.replace(/\s+/g, '')}`}>{shortPhone}</a>
                                    }
                                    {phone && phone.length > 0 &&
                                    <span>
                                        {shortPhone && <span>/</span>}
                                        <a href={`tel:${phone.replace(/\s+/g, '')}`}>
                                            <span className="u_visuallyhidden">{translate.App.Phone}:</span> {phone}
                                        </a>
                                    </span>
                                    }
                                </div>
                                }
                                {email && email.length > 0 &&
                                <div>
                                    <span>{translate.App.Email}: </span>
                                    <a href={`mailto:${email}`}>
                                        <span className="u_visuallyhidden">{translate.App.Email}: </span>
                                        {email}
                                    </a>
                                </div>
                                }
                            </address>
                        </div>

                        <div className="inner-wrapper inner-wrapper--links col-12 col-lg-5">
                            <ul className="u_list-style-none">
                                {privacyLink &&
                                <li>
                                    <EpiLink url={privacyLink.url} >{privacyLink.name}</EpiLink>
                                </li>
                                }
                                {footerLink &&
                                <li>
                                    <EpiLink url={footerLink.url} >{footerLink.name}</EpiLink>
                                </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

Footer.propTypes = {
    language: PropTypes.object,
    epiDataModel: PropTypes.object,
    epiDataModelLoaded: PropTypes.bool
};

const mapStateToProps = state => ({
    language: state.app.language,
    epiDataModel: state.app.epiDataModel,
    epiDataModelLoaded: state.app.epiDataModelLoaded
});

export default connect(mapStateToProps, {})(Footer);
