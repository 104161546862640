import React from 'react';
import PropTypes from 'prop-types';
import { translations } from '../../sharedJs/translations';
import EpiLink from '../links/epiLink/EpiLink';
import SvgElement from '../uiElements/svgElement/SvgElement';

import arrowRight from '../../assets/icons/icon-arrow-right-black.svg';
import iconPrivat from '../../assets/icons/icon-meny-privatpersoner.svg';
import iconNaering from '../../assets/icons/icon-meny-naering.svg';
import iconMiljostatus from '../../assets/logo/logo.svg';

// import logo from '../../assets/logo/logo.svg';


const TopListItem = (props) => {
    const getIcon = (iconName) => {
        switch (iconName) {
        case 'privatpersoner':
            return iconPrivat;
        case 'naering':
            return iconNaering;
        case 'miljostatus':
            return iconMiljostatus;
        default:
            return iconPrivat;
        }
    };

    const translate = translations(props.language.name);
    if (props.active && !props.data.hasChildren) {
        return (
            <li
                className="c_nav-list__item c_nav-list__item--level-block1"
            >
                <div className="c_nav-list__item-content c_nav-list__item-content--active">
                    {props.data.icon &&
                    <SvgElement
                        src={getIcon(props.data.icon)}
                        title={`${translate.App.Icon} for ${props.data.pageName}`}
                        desc={translate.App.Choose}
                        className="c_nav-list-item__icon"
                    />
                    }
                    <span className="u_font-weight-medium">{props.data.pageName}</span>
                </div>
            </li>
        );
    }
    return (
        <li
            className="c_nav-list__item c_nav-list__item--level-block1"
        >
            <EpiLink className="c_nav-list__item-content" url={props.data.url} onFocus={() => props.focusHandler()}>
                {props.data.icon &&
                <SvgElement
                    src={getIcon(props.data.icon)}
                    title={`${translate.App.Icon} for ${props.data.pageName}`}
                    desc={translate.App.Choose}
                    className="c_nav-list-item__icon"
                />
                }
                <span className={props.active ? 'u_font-weight-medium' : ''}>{props.data.pageName}</span>
                <SvgElement
                    src={arrowRight}
                    title={`${translate.App.GoTo} ${props.data.pageName}`}
                    desc={translate.App.ArrowRight}
                    className="c_nav-list-item__arrow-right"
                />
            </EpiLink>
        </li>
    );
};

TopListItem.propTypes = {
    data: PropTypes.object.isRequired,
    active: PropTypes.bool,
    focusHandler: PropTypes.func,
    // topLevelBlock: PropTypes.number,
    icon: PropTypes.any,
    language: PropTypes.object
};

TopListItem.defaultProps = {
    active: false
};

export default TopListItem;
