import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getComponentTypeForContent from './api/getComponentTypeForContent';
import { EPI_COMPONENTS } from './epiComponents';

class BlockComponentSelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blockComponent: this.getComponentTypeForBlock(props.model)
        };
    }

    getComponentTypeForBlock(block) {
        return getComponentTypeForContent(block, EPI_COMPONENTS) || 'GenericBlock';
    }

    render() {
        const BlockComponent = this.state.blockComponent.component;

        return (
            <BlockComponent model={this.props.model} />
        );
    }
}

BlockComponentSelector.propTypes = {
    model: PropTypes.any
};

export default BlockComponentSelector;
