import React, { Component } from 'react';
import PropTypes from 'prop-types';
import XhtmlBlock from '../../xhtmlBlock/XhtmlBlock';

import './_boxedContentBlock.scss';

class BoxedContentBlock extends Component {
    render() {
        const {
            heading,
            body
        } = this.props.model;

        return (
            <div className="c-block c-boxed-content-block">
                <h2 className="c-boxed-content-block__heading">{heading}</h2>
                <XhtmlBlock model={body} id={`BoxedContent${this.props.model.heading}`} />
            </div>
        );
    }
}

BoxedContentBlock.propTypes = {
    model: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        body: PropTypes.array.isRequired
    })
};


export default BoxedContentBlock;
