import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BlockComponentSelector from '../../BlockComponentSelector';

const XhtmlBlock = props => (
    <Fragment>
        {props.model.map((item, index) => {
            if (item.isBlock) {
                return (
                    <BlockComponentSelector
                        model={item.blockModel}
                        key={`xhtmlBlockKey__${props.id}-${index}`} // eslint-disable-line
                    />
                );
            }
            return (
                <div
                    key={`xhtmlBlockKey__${props.id}-${index}`}  // eslint-disable-line
                    dangerouslySetInnerHTML={{ __html: item.xhtmlString }} // eslint-disable-line
                >
                </div>
            );
        })}
    </Fragment>
);

XhtmlBlock.propTypes = {
    model: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired
};

export default XhtmlBlock;
