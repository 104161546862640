import StartPage from './templates/startPage/StartPage';
import DataTestBlock from './templates/TEMP_TESTPAGES/DataTestBlock';
import DataArticlePage from './templates/dataArticlePage/DataArticlePage';
import BoxedContentBlock from './components/contentBlocks/BoxedContent/BoxedContentBlock';
import FileExplorerBlock from './components/contentBlocks/fileExplorerBlock/FileExplorerBlock';

//  import SubjectPage from './templates/subjectPage/SubjectPage';
//  import SeaIndicatorPage from './templates/subjectPage/SeaIndicatorPage';
//  import TransportPage from './templates/transportPage/TransportPage';
//  import ArticlePage from './templates/articlePage/ArticlePage';
//  import TestBlock from './templates/TEMP_TESTPAGES/TestBlock';
//  import CallToActionLink from './components/links/callToActionLink/CallToActionLink';
//  import AccordionList from './components/contentBlocks/accordionList/AccordionList';
//  import StandardMap from './components/maps/standardMap/StandardMap';


export const EPI_COMPONENTS = [
    {
        id: 'DataStartPage',
        component: StartPage
    },
    {
        id: 'TestBlock(1)',
        component: DataTestBlock
    },
    {
        id: 'DataArticlePage',
        component: DataArticlePage
    },
    {
        id: 'BoxedContentBlock',
        component: BoxedContentBlock
    },
    {
        id: 'FileExplorerBlock',
        component: FileExplorerBlock
    }
];

export default EPI_COMPONENTS;
