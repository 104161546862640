import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DataTestBlock extends Component {
    render() {
        return (
            <div>
                <h2>{this.props.model.name}</h2>
                <p>{this.props.model.title}</p>
            </div>
        );
    }
}

DataTestBlock.propTypes = {
    model: PropTypes.any
};

export default DataTestBlock;
