import store from '../store';

function opeResolver(propertyName) {
    const appState = store.getState().app;

    if (appState.inEditMode && appState.isEditable) {
        return propertyName;
    }
    return null;
}

export default opeResolver;
