import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BlockComponentSelector from '../../BlockComponentSelector';

class ContentArea extends Component {
    render() {
        return (
            <div>
                {this.props.model && this.props.model.map(block => (
                    <BlockComponentSelector model={block} key={`blockKey_${block.name}_${block.contentLink.id}`} />
                ))}
            </div>
        );
    }
}

ContentArea.propTypes = {
    model: PropTypes.any
};

export default ContentArea;
