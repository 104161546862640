import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import Menu from 'react-burger-menu/lib/menus/slide';
import { translations } from '../../sharedJs/translations';
import TopLevelList from './TopLevelList';
import SubLevelList from './SubLevelList';
// import '../../styles/_utilities.scss';
import './_navMenu.scss';

import { bulkSetAttribute } from '../../sharedJs/utilityFunctions';

class NavMenu extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.navMenuRef = React.createRef();
        if (this.props.currentLevel) {
            const { currentLevel } = this.props;
            this.state = {
                showTopLevel: (parseInt(currentLevel.levelNum, 10) === 1) || (currentLevel.levelNum === 2 && !currentLevel.hasChildren),
                showLevelId: currentLevel.hasChildren ? currentLevel.id : currentLevel.parentId,
                selectedLevel: currentLevel.hasChildren ?
                    currentLevel : this.props.epiDataModel.header.menu.find(item => item.id === currentLevel.parentId),
                navClassName: 'c_master-nav'
            };
        } else {
            this.state = {
                showTopLevel: true,
                showLevelId: 1,
                selectedLevel: null,
                navClassName: 'c_master-nav'
            };
        }
    }

    componentDidMount() {
        const navElement = this.navMenuRef.current;
        const aTags = navElement.getElementsByTagName('a');
        const buttonTags = navElement.getElementsByTagName('button');
        bulkSetAttribute({ items: [...aTags, ...buttonTags], attr: 'tabIndex', value: '-1' });
        document.body.addEventListener('click', this.closeOnClickOutside);
        const translate = translations(this.props.language.name);
        document.querySelector('.bm-item-list').setAttribute('aria-label', translate.App.MainMenu);

        /* if (document.querySelector('.c_nav-section-list').clientHeight >
            document.querySelector('.bm-menu').clientHeight) {
            this.setState({navClassName: `${this.state.navClassName} ${this.state.navClassName}--overflowing`}); // eslint-disable-line
        } */
    }

    componentDidUpdate(prevProps) {
        const navElement = this.navMenuRef.current;
        const aTags = navElement.getElementsByTagName('a');
        const buttonTags = navElement.getElementsByTagName('button');
        if (this.props.isOpen) {
            bulkSetAttribute({ items: [...aTags, ...buttonTags], attr: 'tabIndex', value: '0' });
        } else {
            bulkSetAttribute({ items: [...aTags, ...buttonTags], attr: 'tabIndex', value: '-1' });
        }

        if (prevProps.language.name !== this.props.language.name) {
            const translate = translations(this.props.language.name);
            document.querySelector('.bm-item-list').setAttribute('aria-label', translate.App.MainMenu);
        }
        // If level number has changed, or current level id has changed AND the level has children; change level to show:
        if (prevProps.currentLevel && this.props.currentLevel && ((this.props.currentLevel.levelNum !== prevProps.currentLevel.levelNum) ||
            (this.props.currentLevel.id !== prevProps.currentLevel.id)) &&
            this.props.currentLevel.hasChildren) {
            this.changeLevelToShow(this.props.currentLevel.id);
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.closeOnClickOutside);
    }

    closeOnClickOutside(event) {
        // contains = function that checks if selected element is child of certain parent element
        const contains = (parent, child) => parent !== child && parent.contains(child);
        const parentElement = document.querySelector('.c_master-nav');
        const clickedElement = event.target;
        // Check if menu is open and clicked element is not inside master-nav
        // If OK, close menu and prevent other click events below from firing
        if (this.props.isOpen && !contains(parentElement, clickedElement)) {
            this.props.closeMenu();
            event.stopPropagation();
            event.preventDefault();
        }
    }

    changeLevelToShow(levelId) {
        const levelObj = this.props.epiDataModel.header.menu.find(item => item.id === levelId);

        if (levelId === null || levelId === undefined || (levelObj && parseInt(levelObj.parentId, 10) === -1)) {
            this.setState({ showTopLevel: true, showLevelId: -1, selectedLevel: this.props.epiDataModel.header.menu }); /* eslint-disable-line */
        } else {
            this.setState({ showTopLevel: false, showLevelId: parseInt(levelId, 10), selectedLevel: levelObj }); /* eslint-disable-line */
        }
    }

    render() {
        const translate = translations(this.props.language.name);

        return (
            <div
                id="masterNavMenu"
                className={`${this.state.navClassName}${this.props.isOpen ? ' c_master-nav--open' : ''}`}
                ref={this.navMenuRef}
            >
                <Menu
                    right
                    customBurgerIcon={false}
                    customCrossIcon={false}
                    width="100%"
                    isOpen={this.props.isOpen}
                    className="c_master-nav__menu"
                    bodyClassName="u_master-nav-menu-open"
                    aria-label={translate.App.MainMenu}
                >
                    {this.props.epiDataModelLoaded && this.props.epiDataModel.header.menu &&
                    <Fragment>
                        {this.state.showTopLevel || this.state.showLevelId === 1 ?
                            <TopLevelList
                                closeMenu={this.props.closeMenu}
                                focusHandler={this.props.focusHandler}
                                isOpen={this.props.isOpen}
                                translate={translate}
                                language={this.props.language}
                                pathname={this.props.location.pathname}
                                menuItems={this.props.epiDataModel.header.menu}
                            />
                            :
                            <SubLevelList
                                showLevelId={this.state.showLevelId}
                                closeMenu={this.props.closeMenu}
                                focusHandler={this.props.focusHandler}
                                changeLevelToShow={this.changeLevelToShow}
                                isOpen={this.props.isOpen}
                                translate={translate}
                                language={this.props.language}
                                pathname={this.props.location.pathname}
                                menuItems={this.props.epiDataModel.header.menu}
                                levelObject={this.state.selectedLevel}
                                parentObject={this.props.epiDataModel.header.menu.find(item => item.id === this.state.selectedLevel.parentId)}
                            />
                        }
                        {/* <button
                            className="c_close-button c_close-button--focus-to-show"
                            onFocus={() => this.props.focusHandler()}
                            onClick={() => this.props.closeMenu()}
                            aria-expanded={this.props.isOpen}
                        >
                            <span className="u_visuallyhidden">{translate.App.CloseMenu}</span>
                        </button> */}
                    </Fragment>
                    }
                </Menu>
            </div>
        );
    }
}

NavMenu.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeMenu: PropTypes.func.isRequired,
    location: PropTypes.object,
    focusHandler: PropTypes.func,
    currentLevel: PropTypes.object,
    language: PropTypes.object,
    epiDataModel: PropTypes.object,
    epiDataModelLoaded: PropTypes.bool
};

const mapStateToProps = state => ({
    language: state.app.language,
    epiDataModel: state.app.epiDataModel,
    epiDataModelLoaded: state.app.epiDataModelLoaded
});

export default connect(mapStateToProps, {})(NavMenu);
