import { combineReducers } from 'redux';
import appReducer from './appReducer';
import sitesearchReducer from './sitesearchReducer';
import feedbackBarReducer from './feedbackBarReducer';
import glossaryReducer from './glossaryReducer';
import mapReducer from './mapReducer';

export default combineReducers({
    app: appReducer,
    feedbackBar: feedbackBarReducer,
    siteSearch: sitesearchReducer,
    glossary: glossaryReducer,
    map: mapReducer
});
