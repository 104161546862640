import 'babel-polyfill';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import api from './api/api';
import { updateModelByUrl, setEpiModelLoading } from './actions/index';
import Header from './components/contentBlocks/header/Header';
import Footer from './components/contentBlocks/footer/Footer';
import PageComponentSelector from './PageComponentSelector';

import store from './store';

import './styles/main.scss';
import './styles/_grid.scss';

// `epiMessages` does not export anything but registers the `beta/contentSaved`
// and `beta/epiReady` message handlers that updates the vuex store.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#Import_a_module_for_its_side_effects_only
import './epiMessages'; // eslint-disable-line
import BreadCrumbs from './components/breadcrumbs/BreadCrumbs';


class App extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.onRouteChange(this.props.history.location);
        store.subscribe(() => null);
        this.state = {
            lang: '-1'
        };
    }

    componentDidMount() {
        this.props.history.listen(this.onRouteChange);
    }

    onRouteChange(route) {
        // route.pathname = Your next route

        // Handle what you need to do here
        // if you need to redirect you can do this
        // this.props.history.replace({ pathname: '/desiredRoute' });
        const parameters = {
            expand: '*'
        };

        if (store.getState().app.epiDataModel.url !== route.pathname) {
            store.dispatch(setEpiModelLoading(true));
            api.getContentByFriendlyUrl(route.pathname + route.search, parameters).then((response) => {
                store.dispatch(updateModelByUrl(response.data));
                window.scroll(0, 0);
                document.body.style.overflow = 'auto';
            });
        }
    }

    render() {
        return (
            <Provider store={store}>
                <div data-test="test" className="app-wrapper">
                    <Header
                        location={this.props.history.location}
                        className="c_master-header"
                    />
                    <BreadCrumbs />
                    <Route
                        path="*"
                        component={PageComponentSelector}
                    />
                    <Footer
                        history={this.props.history}
                        selectedLanguage={this.state.lang}
                    />
                </div>
            </Provider>
        );
    }
}

App.propTypes = {
    history: PropTypes.object
};

export default withRouter(App);

