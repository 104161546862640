import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../styles/_grid.scss';
import './_dataArticlePage.scss';
// import ResponsibleByline from '../../components/miljostatusComponents/responsibleByline/ResponsibleByline';
import opeResolver from '../../api/opeResolver';
import XhtmlBlock from '../../components/xhtmlBlock/XhtmlBlock';
import ContentArea from '../../components/properties/ContentArea';

class DataArticlePage extends Component {
    render() {
        const {
            pageHeading,
            preamble,
            mainBody,
            contentArea
        } = this.props.model;

        return (
            <div className="t_article-page wrapper container-fluid">
                <div className="c_article-page-header row">
                    <header className="col-12 col-lg-7">
                        <h1 className="c_article-page-header__heading">
                            {pageHeading}
                        </h1>
                        <p className="c_article-page-header__preamble">
                            {preamble}
                        </p>
                    </header>
                </div>
                {mainBody && mainBody.length > 0 &&
                <div className="row">
                    <div
                        data-epi-edit={opeResolver('MainBody')}
                        className="c_article-page-main-content col-12 col-lg-7"
                    >
                        <XhtmlBlock model={mainBody} id="MainBody" />
                    </div>
                </div>
                }
                <div className="row">
                    <div className="col-12 col-lg-9">
                        <ContentArea model={contentArea} />
                    </div>
                </div>
            </div>
        );
    }
}

DataArticlePage.propTypes = {
    model: PropTypes.shape({
        pageHeading: PropTypes.string.isRequired,
        preamble: PropTypes.string.isRequired,
        changed: PropTypes.string.isRequired,
        responsible: PropTypes.arrayOf(PropTypes.shape({
            url: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        })),
        mainBody: PropTypes.any,
        contentArea: PropTypes.any
    })
};

export default DataArticlePage;
