import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EpiLink from '../links/epiLink/EpiLink';

import './_breadcrumbs.scss';


class BreadCrumbs extends Component {
    render() {
        if (!this.props.epiDataModel || !this.props.epiDataModelLoaded) {
            return null;
        }
        if (this.props.epiDataModel.header.breadCrumbs.length <= 1) {
            return null;
        }

        const {
            breadCrumbs
        } = this.props.epiDataModel.header;

        return (
            <div className="c_header-breadcrumbs u_hidden--mobile wrapper container-fluid">
                <nav aria-label="Brødsmulesti" className="row">
                    <ul className="col-12 u_list-style-none">
                        {breadCrumbs.map((crumb, index) => {
                            if (index === breadCrumbs.length - 1) {
                                return (
                                    <li
                                        key={`breadcrumb_key__${crumb.id}`}
                                        className="c_header-breadcrumbs__item"
                                    >
                                        {crumb.text}
                                    </li>
                                );
                            }
                            return (
                                <li
                                    key={`breadcrumb_key__${crumb.id}`}
                                    className="c_header-breadcrumbs__item"
                                >
                                    <EpiLink
                                        url={crumb.url}
                                    >{crumb.text}
                                    </EpiLink>
                                    <span className="c_header-breadcrumbs__item-seperator">/</span>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </div>
        );
    }
}

BreadCrumbs.propTypes = {
    breadCrumbs: PropTypes.array,
    epiDataModel: PropTypes.object,
    epiDataModelLoaded: PropTypes.bool
};

const mapStateToProps = state => ({
    epiDataModel: state.app.epiDataModel,
    epiDataModelLoaded: state.app.epiDataModelLoaded
});


export default connect(mapStateToProps, {})(BreadCrumbs);
