import dataOgProdukter from '../assets/icons/icon-data_data_og_produkter.svg';
import katalog from '../assets/icons/icon-data_katalog.svg';
import varDatapolitikk from '../assets/icons/icon-data_var_datapolitikk.svg';

// eslint-disable-next-line import/prefer-default-export
export const getIcon = (iconName) => {
    switch (iconName) {
    case 'data_data_og_produkter':
        return dataOgProdukter;
    case 'data_katalog':
        return katalog;
    case 'data_var_datapolitikk':
        return varDatapolitikk;
    default:
        return '';
    }
};