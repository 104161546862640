import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SvgElement from '../uiElements/svgElement/SvgElement';
import TopListItem from './TopListItem';
import closeBlack from '../../assets/icons/icon-close-black.svg';

const TopLevelList = props => (
    <Fragment>
        <div>
            <button
                className="c_close-button"
                onClick={() => props.closeMenu()}
                onFocus={() => props.focusHandler()}
                aria-expanded={props.isOpen}
            >
                <span className="c_close-button__inner-wrapper">
                    <span className="c_close-button__text">{props.translate.App.Close}</span>
                    <SvgElement
                        src={closeBlack}
                        title={props.translate.App.CloseMenu}
                        desc={props.translate.App.ButtonToCloseMenu}
                    />
                </span>
            </button>
        </div>
        <div className="c_nav-section-list">
            <ul className="u_list-style-none c_nav-list c_nav-list--top-level">
                {props.menuItems.map((levelItem) => {
                    if (levelItem.levelNum === 2) {
                        return (
                            <TopListItem
                                key={`${levelItem.id}_${levelItem.url}_${levelItem.topLevelBlock}`}
                                data={levelItem}
                                changeLevelToShow={props.changeLevelToShow}
                                active={props.pathname === levelItem.url}
                                focusHandler={props.focusHandler}
                                topLevelBlock={levelItem.topLevelBlock}
                                language={props.language}
                            />
                        );
                    }
                    return false;
                })}
            </ul>
        </div>
    </Fragment>
);

TopLevelList.propTypes = {
    closeMenu: PropTypes.func,
    focusHandler: PropTypes.func,
    changeLevelToShow: PropTypes.func,
    isOpen: PropTypes.bool,
    translate: PropTypes.object,
    language: PropTypes.object,
    pathname: PropTypes.string,
    menuItems: PropTypes.array
};

export default TopLevelList;
