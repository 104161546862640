import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translations } from '../../../sharedJs/translations';
import SvgElement from '../../uiElements/svgElement/SvgElement';
import EpiLink from '../epiLink/EpiLink';

import arrowRight from '../../../assets/icons/icon-arrow-right-black.svg';
import arrowRightGreen from '../../../assets/icons/icon-arrow-right-darkblue.svg';
import redirectIcon from '../../../assets/icons/icon-redirect-black.svg';

import '../../../styles/_utilities.scss';
import './_shortcut.scss';


const Shortcut = (props) => {
    const renderContent = (props) => {
        const translate = translations(props.language.name);
        if (props.linkToOldWebsite) {
            return (
                <Fragment>
                    <div className="c_shortcut__text-container">
                        <span className="c_shortcut__title">{props.title}</span>
                        <span className="c_shortcut__description">
                            {props.description &&
                            props.description
                            } ({translate.App.OpensInNewWindow})
                        </span>
                    </div>
                    <div className="c_shortcut__arrow-right c_shortcut__redirect-icon">
                        <SvgElement
                            src={redirectIcon}
                            title={translate.App.ContentIsOnOldSite}
                            desc={translate.App.ClickToOpen}
                        />
                    </div>
                </Fragment>
            );
        }
        if (props.isBlockShortcut) {
            return (
                <Fragment>
                    {props.icon &&
                    <div className="c_shortcut__icon">
                        <SvgElement
                            src={props.icon}
                            title={props.title}
                            desc={`${translate.App.IllustrationOf} ${props.title}`}
                        />
                    </div>
                    }
                    <div className="c_shortcut__text-container">
                        <span className="c_shortcut__title">{props.title}</span>
                        <span className="c_shortcut__description">{props.description}</span>
                    </div>
                </Fragment>
            );
        }
        if (props.isAudienceShortcut) {
            return (
                <Fragment>
                    {props.icon &&
                    <div className="c_shortcut__icon">
                        <SvgElement
                            src={props.icon}
                            title={props.title}
                            desc={`${translate.App.IllustrationOf} ${props.title}`}
                        />
                    </div>
                    }
                    <div className="c_shortcut__text-container">
                        <span className="c_shortcut__title">{props.title}</span>
                        <span className="c_shortcut__description">{props.description}</span>
                    </div>
                    <div className="c_shortcut__arrow-right u_hidden--mobile">
                        <SvgElement
                            src={arrowRightGreen}
                            title={translate.App.ArrowRight}
                            desc={translate.App.ClickToGoToPage}
                        />
                    </div>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <div className="c_shortcut__text-container">
                    <span className="c_shortcut__title">{props.title}</span>
                </div>
                <div className="c_shortcut__arrow-right">
                    <SvgElement
                        src={arrowRight}
                        title={translate.App.ArrowRight}
                        desc={translate.App.ClickToGoToPage}
                    />
                </div>
            </Fragment>
        );
    };

    const getClassNames = () => {
        let cN = 'c_shortcut';
        if (props.linkToOldWebsite) {
            cN = `${cN} c_shortcut--old-website`;
        } else if (props.isAudienceShortcut) {
            cN = `${cN} c_shortcut--audience`;
        } else if (props.isBlockShortcut) {
            cN = `${cN} c_shortcut--block`;
        } else if (props.isWarningShortcut) {
            cN = `${cN} c_shortcut--warning`;
        } else if (props.inverted) {
            cN = `${cN} c_shortcut--inverted`;
        }
        return cN;
    };

    return (
        <div className="g_container g_container--full">
            <EpiLink
                url={props.url}
                className={getClassNames()}
            >
                {renderContent(props)}
            </EpiLink>
        </div>
    );
};

Shortcut.propTypes = {
    title: PropTypes.string.isRequired, //eslint-disable-line
    url: PropTypes.string.isRequired,
    icon: PropTypes.object, //eslint-disable-line
    description: PropTypes.string, //eslint-disable-line
    isAudienceShortcut: PropTypes.bool,
    isBlockShortcut: PropTypes.bool,
    isWarningShortcut: PropTypes.bool,
    inverted: PropTypes.bool,
    linkToOldWebsite: PropTypes.bool,
    language: PropTypes.object //eslint-disable-line
};

Shortcut.defaultProps = {
    isBlockShortcut: false,
    isAudienceShortcut: false,
    linkToOldWebsite: false
};

const mapStateToProps = state => ({
    language: state.app.language
});

export default connect(mapStateToProps, {})(Shortcut);
